import React from "react";
import {
    BarChart,
    Bar,
    Rectangle,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Label,
} from "recharts";
import CustomLegend from "./CustomLegend";
import { Complexities } from "@shared/interfaces/new_dimension.interface";

interface BucketProps {
    dimensionString: string;
    property: Complexities;
}

const interpolateColor = (
    color1: string,
    color2: string,
    factor: number
): string => {
    if (factor < 0) factor = 0;
    if (factor > 1) factor = 1;

    const hex = (c: number) => {
        const hexValue = Math.round(c).toString(16);
        return hexValue.length === 1 ? "0" + hexValue : hexValue;
    };

    const rgb1 = color1.match(/\w\w/g)?.map((x) => parseInt(x, 16)) || [
        0, 0, 0,
    ];
    const rgb2 = color2.match(/\w\w/g)?.map((x) => parseInt(x, 16)) || [
        0, 0, 0,
    ];

    const interpolatedColor = rgb1.map((channel, index) => {
        return Math.round(channel + factor * (rgb2[index] - channel));
    });

    return `#${hex(interpolatedColor[0])}${hex(interpolatedColor[1])}${hex(
        interpolatedColor[2]
    )}`;
};

const getColor = (
    hoursDriven: number,
    minHoursDriven: number,
    maxHoursDriven: number
): string => {
    const range = maxHoursDriven - minHoursDriven;
    const factor = (hoursDriven - minHoursDriven) / range;

    return interpolateColor("#719dee", "#020269", factor);
};

export const Bucket: React.FC<BucketProps> = ({
    dimensionString,
    property,
}) => {
    const newData = [
        {
            name: "low",
            ciLower: property.low.ci[0],
            ciUpper: property.low.ci[1],
            rate: property.low.rate,
            km: property.low.hours_driven,
            hours_driven: property.low.hours_driven,
            hours_needed: property.low.hours_needed,
            inappropriate_behavior_count:
                property.low.inappropriate_behavior_count,
            ci: property.low.ci,
        },
        {
            name: "medium",
            ciLower: property.medium.ci[0],
            ciUpper: property.medium.ci[1],
            rate: property.medium.rate,
            km: property.medium.hours_driven,
            hours_driven: property.medium.hours_driven,
            hours_needed: property.medium.hours_needed,
            inappropriate_behavior_count:
                property.medium.inappropriate_behavior_count,
            ci: property.medium.ci,
        },
        {
            name: "high",
            ciLower: property.high.ci[0],
            ciUpper: property.high.ci[1],
            rate: property.high.rate,
            km: property.high.hours_driven,
            hours_driven: property.high.hours_driven,
            hours_needed: property.high.hours_needed,
            inappropriate_behavior_count:
                property.high.inappropriate_behavior_count,
            ci: property.high.ci,
        },
    ];

    const maxHoursDriven = Math.max(
        ...newData.map((item) => item.hours_driven)
    );
    const minHoursDriven = Math.min(
        ...newData.map((item) => item.hours_driven)
    );

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload; // Get the data for the hovered bar
            return (
                <div
                    style={{
                        backgroundColor: "white",
                        padding: "10px",
                        border: "1px solid #ccc",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <p>{`ciUpper: ${data.ciUpper.toFixed(2)}`}</p>
                    <p>{`ciLower: ${data.ciLower.toFixed(2)}`}</p>
                    <p>{`ciRate: ${data.rate.toFixed(2)}`}</p>
                    <p>{`inappropriate behaviors: ${data.inappropriate_behavior_count}`}</p>
                    <p>{`hours driven: ${data.hours_driven.toFixed(0)}`}</p>
                    <p>{`hours needed: ${data.hours_needed.toFixed(0)}`}</p>
                </div>
            );
        }

        return null;
    };

    return (
        <div className="border-[1px] h-full w-full rounded-md dark:bg-zinc-900 dark:border-zinc-600 bg-white relative">
            <CustomLegend maxHoursDriven={maxHoursDriven} />
            <ResponsiveContainer
                height="65%"
                width="100%"
            >
                <BarChart
                    data={newData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 30,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name">
                        <Label
                            value="Complexity"
                            offset={-20}
                            position="insideBottom"
                            fontSize={15}
                        />
                    </XAxis>

                    <YAxis
                        label={{
                            value: "Behaviors per 1000h",
                            angle: -90,
                            position: "insideBottomLeft",
                            offset: 2,
                            dy: -20,
                            style: {
                                fontSize: "14px",
                            },
                        }}
                        // No need to specify the domain here
                        tickCount={5} // Set the number of ticks on the Y-axis
                        tickFormatter={(value) => value.toFixed(2)} // Format tick labels with 2 decimal places
                    />

                    <Tooltip content={<CustomTooltip />} />
                    <Bar
                        dataKey="ci"
                        name="Behaviors / 1000 hours"
                        fill="rgba(0, 26, 72, 0.5)"
                        shape={(props: any) => {
                            const { x, y, width, height, payload } = props;
                            const barFill = getColor(
                                payload.hours_driven,
                                minHoursDriven,
                                maxHoursDriven
                            );
                            return (
                                <>
                                    {height > 0 && (
                                        <>
                                            {/* The main bar */}
                                            <Rectangle
                                                x={x + width / 4}
                                                y={y}
                                                width={width / 2}
                                                height={height}
                                                fill={barFill}
                                                opacity={0.8}
                                            />
                                            {/* The black horizontal stripe */}
                                            <line
                                                x1={x + width / 4} // Align the start of the line with the start of the main bar
                                                x2={x + (3 * width) / 4} // Align the end of the line with the end of the main bar
                                                y1={
                                                    y +
                                                    ((payload.ci[1] -
                                                        payload.rate) /
                                                        (payload.ci[1] -
                                                            payload.ci[0])) *
                                                        height
                                                } // Position it in the middle of the bar
                                                y2={
                                                    y +
                                                    ((payload.ci[1] -
                                                        payload.rate) /
                                                        (payload.ci[1] -
                                                            payload.ci[0])) *
                                                        height
                                                } // Position it in the middle of the bar
                                                stroke="#000000"
                                                strokeWidth={2} // Adjust the width of the stripe as needed
                                            />
                                        </>
                                    )}
                                </>
                            );
                        }}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

import axios from "@includes/axios";

export const getDimensions = async (setting: string, categories: string[]) => {
    let categoryParam = "";
    for (let i = 0; i < categories.length; i++) {
        categoryParam += `&category=${categories[i]}`;
    }
    const res = await axios.get(
        `/progression/overview?setting=${setting}${categoryParam}`,
    );
    return res.data;
};

export const getDimensionsForBucket = async (
    setting: string,
    dimension: string,
    categories: string[],
) => {
    let categoryParam = "";
    for (let i = 0; i < categories.length; i++) {
        categoryParam += `&category=${categories[i]}`;
    }
    const res = await axios.get(
        `/progression/dimension?setting=${setting}&dimension=${dimension}${categoryParam}`,
    );
    console.log(res.data);
    return res.data;
};

export const getSurveyBehaviors = async () => {
    const res = await axios.get(`/buckets/get_survey_behaviors`);
    return res.data;
};

export const getHoursDriven = async (setting: string, categories: string[]) => {
    let categoryParam = "";
    for (let i = 0; i < categories.length; i++) {
        categoryParam += `&category=${categories[i]}`;
    }
    const res = await axios.get(
        `/progression/hour-stats?setting=${setting}${categoryParam}`,
    );
    return res.data;
};

export const getCategories = async (setting: string) => {
    const res = await axios.get(`/progression/categories?setting=${setting}`);
    return res.data;
};

interface CardProps {
    title: string;
    value: string;
}

export const Card: React.FC<CardProps> = ({ title, value }) => {
    return (
        <div className="flex w-full h-full rounded-sm border border-stroke bg-white py-6 px-7 shadow-default dark:border-strokedark dark:bg-boxdark">
            <p className="text-zinc-500">{title}</p>
            <h1 className="text-3xl text-zinc-800 font-semibold ml-auto">
                {value}
            </h1>
        </div>
    );
};

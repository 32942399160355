import { useState } from "react";

/**
 * @description A custom hook that manages the token in local storage.
 * @returns {Object} The token, setToken, and removeToken functions.
 * @example
 * const { token, setToken, removeToken } = useToken();
 */
const useToken = () => {
    const getToken = () => {
        const userToken = localStorage.getItem("token");
        return userToken && userToken;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken: string) => {
        localStorage.setItem("token", userToken);
        setToken(userToken);
    };

    const removeToken = () => {
        localStorage.removeItem("token");
        setToken(null);
    };

    return {
        setToken: saveToken,
        token,
        removeToken,
    };
};

export default useToken;

import React from "react";

interface CustomLegendProps {
    maxHoursDriven: number; // Define the prop for maxHoursDriven
}

const CustomLegend: React.FC<CustomLegendProps> = ({ maxHoursDriven }) => {
    const gradient = `linear-gradient(to right, #7fadff, #001a48)`;

    return (
        <div className="flex flex-col dark:text-zinc-400 items-center p-4">
            <div className="flex gap-4 w-full justify-start">
                <div className="flex gap-4">
                    <label className="text-sm w-full text-left font-medium">
                        Average Rate:
                    </label>
                    <div className="flex items-center">
                        <span className="h-1 w-8 bg-black"></span>
                    </div>
                </div>
            </div>
            <label className="text-sm font-medium mb-2">
                Operational Hours
            </label>

            <div
                style={{
                    width: "60%",
                    height: "20px",
                    backgroundImage: gradient,
                }}
            ></div>
            <div className="w-9/12 flex justify-between text-sm mt-2">
                <span>0 hours</span>
                <span>{Math.round(maxHoursDriven)} hours</span>
            </div>
        </div>
    );
};

export default CustomLegend;

export const DataUploader: React.FC = () => {
    return (
        <div className="flex flex-col p-8 w-full h-full gap-4">
            <div className="flex w-full items-center">
                <h1 className="font-semibold text-3xl">Data Overview</h1>
                <button className="ml-auto bg-blue-600 py-2 px-4 text-white font-medium rounded-md ">
                    Upload
                </button>
            </div>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs w-full text-gray-700 uppercase bg-slate-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className="">
                        {[
                            "Name",
                            "Type",
                            "Vehicle Type",
                            "Provider",
                            "Size",
                            "Time range",
                            "Date",
                        ].map((header, index) => (
                            <th scope="col" className="py-3" key={index}>
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="h-full">
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

import { getDimensions } from "@utils/dimension.utils";
import { useEffect, useState } from "react";
import { IoIosWarning, IoMdCheckbox } from "react-icons/io";

interface DimensionPerformanceSummary {
    dimension: string;
    progress: number[];
    hours: number;
    behaviour_ratio: any;
}

interface PerformanceTableProps {
    setting: string;
    categories: string[];
}

export const PerformanceTable: React.FC<PerformanceTableProps> = ({
    setting,
    categories,
}) => {
    const [dimensionPerformanceSummary, setDimensionPerformanceSummary] =
        useState<DimensionPerformanceSummary[]>();

    useEffect(() => {
        const fetchPerformanceData = async () => {
            const response = await getDimensions(setting, categories);
            const data = await response;
            setDimensionPerformanceSummary(
                data as DimensionPerformanceSummary[],
            );
        };
        console.log(categories);
        fetchPerformanceData();
    }, [categories]);
    return (
        <div className="w-full  mb-12 xl:mb-0 px-4 mx-auto mt-24">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded ">
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-base text-blueGray-700">
                                Progress across dimensions
                            </h3>
                        </div>
                    </div>
                </div>

                <div className="block w-full overflow-x-auto">
                    <table className="items-center bg-transparent w-full border-collapse ">
                        <thead>
                            <tr>
                                <th className="head-th">Dimension</th>
                                <th className="head-th">Hours required</th>
                            </tr>
                        </thead>

                        <tbody>
                            {dimensionPerformanceSummary &&
                                dimensionPerformanceSummary.map((data) => {
                                    return (
                                        <tr
                                            className={`cursor-pointer ${
                                                data.progress[0] <
                                                data.progress[1]
                                                    ? `tr-red hover:bg-opacity-100`
                                                    : `tr-blue hover:bg-opacity-100`
                                            }
                                            `}
                                            onClick={() =>
                                                (window.location.href = `/dimension?setting=${setting}&dimension=${data.dimension}`)
                                            }
                                        >
                                            <th className="body-th ">
                                                {/* dimension but capitalise first letter */}
                                                {data.dimension
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    data.dimension.slice(1)}
                                            </th>

                                            <td className="body-td flex items-center gap-2">
                                                <span className="w-16">
                                                    {Math.round(data.hours)}
                                                </span>

                                                {data.hours > 1 ? (
                                                    <IoIosWarning size={20} />
                                                ) : (
                                                    <IoMdCheckbox
                                                        className="text-blue-700"
                                                        size={20}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

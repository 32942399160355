import React, { useState, useEffect } from "react";
import { PerformanceTable } from "@components/PerformanceOverview/PerformanceTable";
import { Link, useSearchParams } from "react-router-dom";
import { Card } from "@components/Card";
import { IoIosArrowBack } from "react-icons/io";
import { getHoursDriven, getCategories } from "@utils/dimension.utils";
import { OverviewHeader } from "@components/OverviewHeader";

export const PerformanceOverview: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    const [hoursDriven, setHoursDriven] = useState<number>(0);
    const [hoursNeeded, setHoursNeeded] = useState<number>(0);
    const [categories, setCategories] = useState<string[]>([]);
    const [setting, category] = [
        searchParams.get("setting"),
        searchParams.get("category"),
    ];

    useEffect(() => {
        const fetchHoursDriven = async (setting: string | null) => {
            if (setting === null) return;
            const resp = await getHoursDriven(setting, selectedCategories);
            setHoursDriven(resp.hours_driven);
            setHoursNeeded(resp.hours_needed);
        };

        const fetchSettings = async (setting: string | null) => {
            if (setting === null) return;
            const resp = await getCategories(setting);
            setCategories(resp);
            setSelectedCategories(resp);
        };
        fetchHoursDriven(category);
        fetchSettings(setting);
    }, []);

    useEffect(() => {
        const fetchHoursDriven = async (setting: string | null) => {
            if (setting === null || selectedCategories.length === 0) return;

            // Fetch hours driven for the array of selected settings
            const resp = await getHoursDriven(setting, selectedCategories);
            setHoursDriven(resp.hours_driven);
            setHoursNeeded(resp.hours_needed);
        };

        // Fetch hours driven whenever selectedCategories or category changes
        fetchHoursDriven(setting);
    }, [category, selectedCategories, setting]);

    return (
        <div className="h-screen flex flex-col w-screen p-8">
            <div className="w-full py-4 px-8 text-xl font-semibold flex text-zinc-600">
                <Link
                    to="/stage-gates"
                    className="flex items-center gap-2 text-zinc-600 hover:text-zinc-800"
                >
                    <IoIosArrowBack />
                    Back
                </Link>
                <img
                    className="ml-auto w-32 h-auto"
                    src="/Logo-Schiphol-Group.svg"
                    alt="Schiphol Logo"
                />
            </div>
            <div className="flex flex-col items-start py-4">
                <OverviewHeader
                    setting={setting}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    categories={categories}
                />
            </div>
            <div className="bg-white rounded-sm border py-6 w-full h-full">
                <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 w-full items-center px-8 pt-4 gap-8">
                    <Card title="Stage Gate" value="1" />
                    <Card title="Next Stage Gate" value="2" />
                    <Card
                        title="Operational Hours (Gathered)"
                        value={hoursDriven.toString()}
                    />
                    <Card
                        title="Operational Hours (Needed)"
                        value={Math.round(hoursNeeded).toString()}
                    />
                </div>
                {setting && (
                    <PerformanceTable
                        setting={setting}
                        categories={selectedCategories}
                    />
                )}
            </div>
        </div>
    );
};

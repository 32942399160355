import { DimensionCard } from "@components/DimensionOverview/DimensionCard";
import { OverviewHeader } from "@components/OverviewHeader";
import {
    Complexities,
    Dimension,
    Weather,
    Infrastructure,
    Traffic,
} from "@shared/interfaces/dimension.interface";
import { getDimensionsForBucket, getCategories } from "@utils/dimension.utils";
import React, { ReactNode, useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useSearchParams } from "react-router-dom";

export const DimensionOverview: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [dimensionString, setDimensionString] = useState<string>("");
    const [dimension, setDimension] = useState<any>();
    const [setting, setSetting] = useState<string | null>(
        searchParams.get("setting"),
    );
    const visionProperties = ["drivingcontrol", "computervision"];
    const [categories, setCategories] = useState<string[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    useEffect(() => {
        const [setting, dimension] = [
            searchParams.get("setting"),
            searchParams.get("dimension"),
        ];

        const fetchCategories = async (setting: string | null) => {
            if (setting === null) return;
            const resp = await getCategories(setting);
            setCategories(resp);
            setSelectedCategories(resp);
        };
        fetchCategories(setting);
        const fetchDimension = async () => {
            if (setting && dimension) {
                const resp = await getDimensionsForBucket(
                    setting,
                    dimension,
                    selectedCategories,
                );
                setDimension(resp);
                setDimensionString(
                    dimension.charAt(0).toUpperCase() + dimension.slice(1),
                );
            }
        };
        fetchDimension();
    }, []);

    useEffect(() => {
        const fetchDimension = async () => {
            if (setting !== null && dimensionString !== "") {
                const resp = await getDimensionsForBucket(
                    setting,
                    dimensionString.toLowerCase(),
                    selectedCategories,
                );
                setDimension(resp);
            }
        };
        fetchDimension();
    }, [selectedCategories, dimensionString, setting]);

    return dimension ? (
        <div className="h-screen flex flex-col w-screen p-8">
            <div className="w-full py-4 px-8 text-xl font-semibold flex text-zinc-600">
                <Link
                    to={`/overview?setting=${setting}`}
                    className="flex items-center gap-2 text-zinc-600 hover:text-zinc-800"
                >
                    <IoIosArrowBack />
                    Back
                </Link>
                <img
                    className="ml-auto w-32 h-auto"
                    src="/Logo-Schiphol-Group.svg"
                    alt="Schiphol Logo"
                />
            </div>
            <div className="flex flex-col items-start py-4">
                <OverviewHeader
                    setting={setting}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    categories={categories}
                />
            </div>
            {dimensionString === "Weather" ? (
                <div className="h-full overflow-y-scroll">
                    <h1 className={"font-bold "}>Driving </h1>
                    {Object.keys(dimension.properties).map((key: string) => {
                        return (
                            visionProperties.includes(key) && (
                                <DimensionCard
                                    key={key}
                                    name={key}
                                    dimensionString={dimensionString}
                                    property={
                                        dimension.properties[
                                            key as keyof typeof dimension.properties
                                        ]
                                    }
                                />
                            )
                        );
                    })}
                    <h1 className={"font-bold "}>Weather Specifics</h1>
                    {Object.keys(dimension.properties).map((key: string) => {
                        return (
                            !visionProperties.includes(key) && (
                                <DimensionCard
                                    key={key}
                                    name={key}
                                    dimensionString={dimensionString}
                                    property={
                                        dimension.properties[
                                            key as keyof typeof dimension.properties
                                        ]
                                    }
                                />
                            )
                        );
                    })}
                </div>
            ) : (
                <div className="h-full overflow-y-scroll">
                    {Object.keys(dimension.properties).map((key) => {
                        return (
                            <DimensionCard
                                key={key}
                                name={key}
                                dimensionString={dimensionString}
                                property={
                                    dimension.properties[
                                        key as keyof typeof dimension.properties
                                    ]
                                }
                            />
                        );
                    })}
                </div>
            )}
        </div>
    ) : (
        <div className="w-full h-screen p-16 flex flex-col gap-4">
            <div className="w-full py-4 px-8 text-xl font-semibold flex text-zinc-600">
                <Link
                    to="/overview?category=busses"
                    className="flex items-center gap-2 text-zinc-600 hover:text-zinc-800"
                >
                    <IoIosArrowBack />
                    Back
                </Link>
            </div>
            <div className="w-full flex justify-center items-center">
                <div className="w-1/2 h-1/2"></div>
            </div>
        </div>
    );
};

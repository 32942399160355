export const OverviewHeader: React.FC<{
    setting: string | null;
    selectedCategories: string[];
    setSelectedCategories: (categories: string[]) => void;
    categories: string[];
}> = ({ setting, selectedCategories, setSelectedCategories, categories }) => {
    return (
        <div className="flex w-full">
            <div className="flex flex-col w-full">
                <h1 className="px-8 py-2 text-3xl dark:text-white font-semibold">
                    {setting?.toUpperCase()}
                </h1>
                <p className="opacity-50 px-8">Service Roads / Baggage Hall</p>
            </div>
            <div className="flex items-center justify-center mt-4">
                {categories.map((category) => (
                    <label key={category} className="mx-2">
                        <input
                            type="button"
                            className={`${
                                selectedCategories.includes(category)
                                    ? "bg-[#1b60db] text-white border-blue-500 font-semibold"
                                    : "bg-white text-zinc-500 border"
                            } border font-semibold py-2 px-4 rounded-sm cursor-pointer`}
                            value={category
                                .split("-")
                                .map(
                                    (word) =>
                                        word[0].toUpperCase() + word.slice(1),
                                )
                                .join(" ")}
                            checked={selectedCategories.includes(category)}
                            onClick={() => {
                                // Update the selected settings array
                                if (
                                    selectedCategories.includes(category) &&
                                    selectedCategories.length > 1
                                ) {
                                    setSelectedCategories(
                                        selectedCategories.filter(
                                            (s) => s !== category,
                                        ),
                                    );
                                } else {
                                    if (
                                        !selectedCategories.includes(category)
                                    ) {
                                        setSelectedCategories([
                                            ...selectedCategories,
                                            category,
                                        ]);
                                    }
                                }
                            }}
                        />
                    </label>
                ))}
            </div>
        </div>
    );
};

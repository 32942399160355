import { useEffect, useState } from "react";
import {
    IoIosArrowBack,
    IoIosArrowDown,
    IoIosWarning,
    IoMdCheckbox,
} from "react-icons/io";

import { Card } from "@components/Card";
import "@shared/interfaces/new_dimension.interface";
import {
    Complexity,
    Complexities,
} from "@shared/interfaces/dimension.interface";

import { Bucket } from "./Bucket";
import { BigButton } from "./BigButton";

interface DimensionCardProps {
    name: string;
    dimensionString: string;
    property: Complexities;
}

const checkConfidencePassed = (complexity: Complexity): JSX.Element => {
    const iconProps = {
        size: 20,
        color:
            complexity.hours_needed === 0 && complexity.hours_driven > 0
                ? "blue"
                : "red",
        className:
            complexity.hours_needed === 0 && complexity.hours_driven > 0
                ? "text-blue-500"
                : "text-red-500",
    };
    return complexity.hours_needed === 0 && complexity.hours_driven ? (
        <IoMdCheckbox {...iconProps} />
    ) : (
        <IoIosWarning {...iconProps} />
    );
};

const TableRow: React.FC<{ level: string; confidence: Complexity }> = ({
    level,
    confidence,
}) => (
    <tr className="bg-white border-b w-full dark:bg-gray-800 dark:border-gray-700">
        <td className="py-4 px-6">{level}</td>
        <td className="py-4 px-6">{confidence.rule}</td>
        <td className="py-4 px-6">{checkConfidencePassed(confidence)}</td>
        <td className="py-4 px-6">{Math.round(confidence.hours_needed)}</td>
        <td className="py-4 px-6">
            {confidence.rate.toFixed(2)}
            {" / 1000h "}
        </td>
    </tr>
);

export const DimensionCard: React.FC<DimensionCardProps> = ({
    name,
    dimensionString,
    property,
}) => {
    const [isTableCollapsed, setIsTableCollapsed] = useState<boolean>(false);
    const [totalHoursNeeded, setTotalHoursNeeded] = useState<number>(0);
    const [readyForAssessment, setReadyForAssessment] = useState<number>(0);

    useEffect(() => {
        const getTotalHoursNeeded = () => {
            const totalComplexityHours =
                property.low.hours_needed +
                property.medium.hours_needed +
                property.high.hours_needed;
            setTotalHoursNeeded(totalComplexityHours);
        };
        const getReadyForAssessment = () => {
            let count = 0;
            if (
                property.low.hours_needed === 0 &&
                property.low.hours_driven > 0
            ) {
                count++;
            }
            if (
                property.medium.hours_needed === 0 &&
                property.medium.hours_driven > 0
            ) {
                count++;
            }
            if (
                property.high.hours_needed === 0 &&
                property.high.hours_driven > 0
            ) {
                count++;
            }
            setReadyForAssessment(count);
        };

        getTotalHoursNeeded();
        getReadyForAssessment();
    }, [property]);

    const getDisplayName = (name: string): string => {
        switch (name) {
            case "day":
                return "Time of Day";
            case "computervision":
                return "Computer Vision";
            case "drivingcontrol":
                return "Driving Control";
            default:
                return name.charAt(0).toUpperCase() + name.slice(1);
        }
    };
    return (
        <div className="px-8">
            <div
                className="w-full p-6 border-b flex cursor-pointer"
                onClick={() => setIsTableCollapsed(!isTableCollapsed)}
            >
                <h1 className="font-semibold text-lg w-full p-0">
                    {getDisplayName(name)}
                </h1>
                <div className="flex items-center gap-2 px-4 text-slate-500">
                    <div className="flex flex-col items-center">
                        <div>L</div>
                        <div
                            className={`w-3 h-3 rounded-full ${
                                property.low.hours_needed === 0 &&
                                property.low.hours_driven > 0
                                    ? "bg-blue-700"
                                    : "bg-red-500"
                            }`}
                        ></div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div>M</div>
                        <div
                            className={`w-3 h-3 rounded-full ${
                                property.medium.hours_needed === 0 &&
                                property.medium.hours_driven > 0
                                    ? "bg-blue-700"
                                    : "bg-red-500"
                            }`}
                        ></div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div>H</div>
                        <div
                            className={`w-3 h-3 rounded-full ${
                                property.high.hours_needed === 0 &&
                                property.high.hours_driven > 0
                                    ? "bg-blue-700"
                                    : "bg-red-500"
                            }`}
                        ></div>
                    </div>
                </div>
                <div className="text-end flex items-center">
                    {!isTableCollapsed ? (
                        <IoIosArrowBack />
                    ) : (
                        <IoIosArrowDown />
                    )}
                </div>
            </div>
            {isTableCollapsed && (
                <div className="flex items-center justify-center w-full">
                    <div className="w-full sm:rounded-lg">
                        <div className="overflow-x-auto mb-4 relative sm:rounded-lg">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs w-full text-gray-700 uppercase bg-slate-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr className="">
                                        {[
                                            "Complexity level",
                                            "Condition",
                                            "Ready for Assessment",
                                            "Hours needed",
                                            "Inappropriate behaviors rate",
                                        ].map((header, index) => (
                                            <th
                                                scope="col"
                                                className="py-3 px-6"
                                                key={index}
                                            >
                                                {header}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <TableRow
                                        level="Low"
                                        confidence={property.low}
                                    />

                                    <TableRow
                                        level="Medium"
                                        confidence={property.medium}
                                    />

                                    <TableRow
                                        level="High"
                                        confidence={property.high}
                                    />
                                </tbody>
                            </table>
                        </div>
                        <div className="w-full h-full flex  gap-4 ">
                            <div className="w-1/3 flex flex-col py-8  gap-4">
                                <Card
                                    title="Complexity Levels Ready for Assessment"
                                    value={`${readyForAssessment}/3`}
                                />
                                <Card
                                    title="Operational Hours (Needed)"
                                    value={Math.round(
                                        parseFloat(totalHoursNeeded.toString())
                                    ).toString()}
                                />

                                <BigButton text="Behaviors" />
                            </div>
                            <div className="w-2/3 py-8">
                                <Bucket
                                    dimensionString={dimensionString}
                                    property={
                                        property
                                        // data["infrastructure"]["properties"][
                                        //     name
                                        // ] as Complexities
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

import { Outlet } from "react-router-dom";

export const Layout: React.FC = () => {
    return (
        <div className="flex md:flex-row flex-col h-screen w-screen bg-slate-50">
            <div className="md:flex-1 flex h-full w-screen">
                <Outlet />
            </div>
        </div>
    );
};

import { Stagegate } from "@components/Home/Stagegate";
import React from "react";

export const Home: React.FC = () => {
    return (
        <div className="snap-mandatory h-screen snap-y overflow-y-scroll dark:bg-zinc-900">
            <div className="h-screen w-screen flex flex-col p-8 snap-start">
                <div className="flex flex-row items-center justify-between w-full">
                    <h1 className="text-3xl dark:text-white font-semibold">
                        Stage Gate Overview
                    </h1>
                    <img
                        className="ml-auto w-28 h-auto"
                        src="/Logo-Schiphol-Group.svg"
                        alt="Schiphol Logo"
                    />
                </div>
                <p className="px-8 text-gray-500">Settings ▼</p>
                <Stagegate />
            </div>
        </div>
    );
};

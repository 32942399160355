interface BigButtonProps {
    text: string;
}

export const BigButton: React.FC<BigButtonProps> = ({ text }) => {
    return (
        <div className="flex flex-col w-full h-full rounded-sm cursor-pointer border border-stroke bg-[#1b60db] p-6 shadow-default dark:border-strokedark dark:bg-boxdark">
            <p className="text-white text-xl h-full">{text}</p>
            <div className="w-full flex justify-end mt-auto">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
            </div>
        </div>
    );
};

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCategoryCompletion } from "@utils/stagegate.utils";
import vehicleData from "@store/vehicle_progression.json";

export const Stagegate: React.FC = () => {
    const stageGates = [
        "Stage Gate 1",
        "Stage Gate 2",
        "Stage Gate 3",
        "Stage Gate 4",
        "Stage Gate 5",
    ];
    const settingNames = ["S5", "S4", "S3"];

    type CategoryElement = {
        categoryName: string;
        url: string;
        settingName: string;
        vehicles: string[];
        setting: number;
    };

    type StageElement = {
        settingName: string;
        stageNumber: number;
        settingNumber: number;
        label: string;
        categories: CategoryElement[];
    };

    const stages: StageElement[][] = [];

    for (let i = 0; i < settingNames.length; i++) {
        // create row
        const row: StageElement[] = [];

        for (let j = 0; j < stageGates.length; j++) {
            const element: StageElement = {
                settingName: settingNames[i],
                settingNumber: i,
                stageNumber: j,
                label: stageGates[j],
                categories: [],
            };
            row.push(element);
        }
        // Add row to matrix
        stages.push(row);
    }

    type SettingDescriptions = {
        [key: string]: JSX.Element | string;
    };
    const settingDescriptions: SettingDescriptions = {
        S5: <span>Maneuvering Area</span>,
        S4: <span>Apron</span>,
        S3: (
            <>
                <span>Service Roads /</span>
                <br />
                <span>Baggage Hall </span>
            </>
        ),
    };

    const stageGateDescriptions: { [key: string]: JSX.Element | string } = {
        "Stage Gate 1": "Safety Operator On Board",
        "Stage Gate 2": "Remote Safety Operator (near vehicle)",
        "Stage Gate 3": "Remote Safety Operator (for interventions)",
        "Stage Gate 4": "2-4 Supervised Autonomous Operations",
        "Stage Gate 5": "Autonomous Operations",
    };

    vehicleData.forEach((vehicle) => {
        const stageGateIndex = vehicle.stageGate;
        const settingIndex = vehicle.setting;

        const category: CategoryElement = {
            url: vehicle.url,
            categoryName: vehicle.categoryName,
            settingName: vehicle.settingName,
            vehicles: vehicle.vehicles,
            setting: vehicle.setting,
        };

        stages[settingIndex][stageGateIndex].categories.push(category);
    });

    const [categoryCompletion, setCategoryCompletion] = useState<{
        [key: string]: boolean;
    }>({});
    const [hoveredCategory, setHoveredCategory] = useState<number | null>(null);

    useEffect(() => {
        const fetchCategoryCompletion = async () => {
            const completionData: { [key: string]: boolean } = {};
            for (const row of stages) {
                for (const stage of row) {
                    for (const category of stage.categories) {
                        const isCompleted = await getCategoryCompletion(
                            category.url,
                        );
                        console.log(isCompleted);
                        completionData[category.url] = isCompleted;
                    }
                }
            }
            setCategoryCompletion(completionData);
        };
        fetchCategoryCompletion();
    }, []);

    return (
        <div className="w-full h-full flex flex-col">
            <div className="grid grid-cols-11 h-full">
                {settingNames.map((settingName, settingIndex) => {
                    return (
                        <React.Fragment key={settingName}>
                            <div className="col-span-1 h-full flex flex-col items-center justify-center text-3xl font-semibold text-zinc-500">
                                <div>{settingName}</div>
                                {/* Display the description directly under the setting name */}
                                <div className="text-sm mt-2 text-center text-gray-500">
                                    {settingDescriptions[settingName]}
                                </div>
                            </div>
                            {stageGates.map((stageGate, stageIndex) => {
                                return (
                                    <div
                                        key={stageGate}
                                        className="flex col-span-2 flex-col h-full w-full border-b border-l"
                                    >
                                        <div className="flex flex-col gap-4 p-8">
                                            {stages[settingIndex][
                                                stageIndex
                                            ].categories.map(
                                                (category, categoryIndex) => {
                                                    return (
                                                        <Link
                                                            key={category.url}
                                                            to={`/overview?setting=${category.settingName}`}
                                                        >
                                                            <div
                                                                className={`cursor-pointer text-zinc-600 ${
                                                                    categoryCompletion[
                                                                        category
                                                                            .url
                                                                    ]
                                                                        ? "bg-blue-200"
                                                                        : "bg-zinc-100"
                                                                } flex px-3 py-1 rounded-md border-2 justify-center items-center gap-2 ${
                                                                    hoveredCategory ===
                                                                    category.setting
                                                                        ? " border-solid border-2 border-blue-500"
                                                                        : ""
                                                                }`}
                                                                onMouseEnter={() => {
                                                                    setHoveredCategory(
                                                                        category.setting,
                                                                    );
                                                                }}
                                                                onMouseLeave={() => {
                                                                    setHoveredCategory(
                                                                        null,
                                                                    );
                                                                }}
                                                            >
                                                                <div className="text-base font-bold">
                                                                    {
                                                                        category.categoryName
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    );
                                                },
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </div>
            <div className="grid grid-cols-11 gap-4 mt-4">
                <div className="col-span-1"></div>
                {stageGates.map((stageGate, stageIndex) => {
                    return (
                        <div
                            key={stageIndex}
                            className="col-span-2 flex flex-col items-center"
                        >
                            <div className="text-2xl font-bold text-gray-500">
                                {stageGate}
                            </div>
                            <div className="text-sm text-gray-500 font-semi-bold mt-2">
                                {stageGateDescriptions[stageGate]}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
